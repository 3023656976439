
















































import { Component, Vue } from 'vue-property-decorator'
import Timer from '@/components/common/Timer.vue'
import HighlightText from '@/components/HighlightText.vue'
import Modal from '@/components/common/Modal.vue'
import CleanPath from '@/helper/clean-path'

@Component({
  components: {
    Timer,
    HighlightText,
    Modal
  }
})

export default class ScreenOne extends Vue {
  $refs!: Vue['$refs'] & { timer: Timer }

  private countdown = 600 // 10min
  private isEnd = false
  private disable = false

  private created (): void {
    this.$root.$emit('line-timer', {
      visibility: false,
      timeName: 'time' + CleanPath(this.$route.path, this.$route.params.name)
    })
  }

  private mounted () {
    setTimeout(() => {
      this.$root.$emit('open-chat')
    }, 1000)
    const lpTag = (window as unknown as any).lpTag
    lpTag.hooks.push({
      name: 'AFTER_GET_LINES',
      callback: function (ev: any) {
        if (ev.data.lines.length) {
          console.log(ev.data.lines[0].metadata)
        }
      }
    })
  }

  private timeIsEnd (): void {
    this.isEnd = true
    this.disable = true
    sessionStorage.removeItem('timerFlag')
    sessionStorage.setItem('timer' + CleanPath(this.$route.path, this.$route.params.name), 'isDone')
  }

  private runTimer (): number {
    if (!sessionStorage.getItem('timer' + CleanPath(this.$route.path, this.$route.params.name))) {
      sessionStorage.setItem('timer' + CleanPath(this.$route.path, this.$route.params.name), 'isRun')
    }
    if (sessionStorage.getItem('time' + CleanPath(this.$route.path, this.$route.params.name))) {
      return Number(sessionStorage.getItem('time' + CleanPath(this.$route.path, this.$route.params.name)))
    } else {
      return this.countdown
    }
  }

  private resetTimer ():void {
    this.disable = true
    this.isEnd = false
    this.$refs.timer.startTimer(this.countdown)
    sessionStorage.setItem('timerFlag', 'run')
    const myWindow = (window as unknown) as any
    if ('safari' in myWindow && 'pushNotification' in myWindow.safari) {
      var permissionData = myWindow.safari.pushNotification.permission('web.com.example.domain')
      this.checkRemotePermission(permissionData)
    } else if (!!myWindow.chrome && (!!myWindow.chrome.webstore || !!myWindow.chrome.runtime)) {
      console.log('chrome Browser')
    } else {
      console.log('Other Browser')
    }
  }

  private checkRemotePermission (permissionData:any): void {
    const myWindow = (window as unknown) as any
    if (permissionData.permission === 'default') {
      myWindow.safari.pushNotification.requestPermission(
        'https://domain.example.com',
        'web.com.example.domain',
        {}, this.checkRemotePermission)
    } else if (permissionData.permission === 'denied') {
      console.log('Permission denied')
    } else if (permissionData.permission === 'granted') {
      console.log('Permission granted')
    }
  }

  private onlyDesktop (): boolean {
    return window.innerWidth > 767
  }

  private destroyed (): void {
    if (sessionStorage.getItem('timer/quidel/4') !== 'isDone') {
      this.$root.$emit('line-timer', {
        visibility: true,
        timeName: 'time/quidel/4'
      })
    }
  }
}
